





































































































































import { Vue, Component, Prop } from "vue-property-decorator";

class CustomerPaymentEditorControlProperties {
    finalizeEnabled: boolean = true;
    approveEnabled: boolean = true;
    deliveredEnabled: boolean = true;
    cancelEnabled: boolean = true;
    rejectEnabled: boolean = true;
    refundedEnabled: boolean = true;
    printEnabled: boolean = true;
    deleteEnabled: boolean = true;
}

export { CustomerPaymentEditorControlProperties };

@Component({})
export default class CustomerPaymentEditorControlView extends Vue {
    @Prop({ default: new CustomerPaymentEditorControlProperties() })
    private properties: CustomerPaymentEditorControlProperties;

    private clicked(button: string) {
        this.$emit("clicked", button);
    }
}
