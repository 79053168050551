








































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import { CustomerPaymentUploadProperties } from "./CustomerPaymentUploadView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import EventManager from "@/utilities/EventManager";
import CustomerPaymentAttachmentService from "@/services/CustomerPaymentAttachmentService";

const CustomerPaymentUploadView = () => import("./CustomerPaymentUploadView.vue");

class CustomerPaymentReceiptProperties {
    visible: boolean = false;
    events = new EventManager();
}

export { CustomerPaymentReceiptProperties };

@Component({
    components: { CustomerPaymentUploadView },
    data: () => ({
        pageNo: null,
        receipt: null,
        receipts: [],
        saving: false
    }),
})
export default class CustomerPaymentReceiptView extends Vue {
    @Prop({ default: new CustomerPaymentReceiptProperties() })
    private properties: CustomerPaymentReceiptProperties;
    @Prop() private customerPaymentUpload: CustomerPaymentUploadProperties;
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    public get payment() {
        return this.customerPaymentInfo.payment;
    }

    public get isDraft() {
        return this.payment.status === "Draft";
    }

    public get isProcessing() {
        return this.payment.status === "Processing";
    }

    public receiptUrl(receipt: any) {
        if (receipt) {
            const service = new CustomerPaymentAttachmentService();
            return service.previewUrl({ id: receipt.id });
        } else {
            return null;
        }
    }

    public receiptChanged() {
        this.$data.receipt = this.$data.receipts[this.$data.pageNo];
    }

    public created() {
        this.load();
        this.properties.events.subscribe("load", this.load);
        this.customerPaymentInfo.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
    }

    public destroyed() {
        this.properties.events.remove("load", this.load);
        this.customerPaymentInfo.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public async load() {
        try {
            const service = new CustomerPaymentAttachmentService();
            const r = await service.get({
                customerPaymentId: this.payment.id,
            });
            this.$data.receipts = r.data.sort((lhs, rhs) => lhs.id - rhs.id);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public upload() {
        if (this.isDraft || this.isProcessing) {
            this.customerPaymentUpload.visible = true;
        }
    }

    public async remove(receipt: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const service = new CustomerPaymentAttachmentService();
            await service.delete(receipt);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }

    public removeDialog(receipt: any) {
        if (this.isDraft || this.isProcessing) {    
            AlertDialogProperties.delete(this.alertDialog, null);
            this.alertDialog.tag = { command: "attachment-remove", receipt };
            this.alertDialog.visible = true;
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "attachment-remove" && button === this.$t("text.yes")) {
                await this.remove(tag.receipt);
            }
        }
    }
}
