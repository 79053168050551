











































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import CustomerOrderService from "@/services/CustomerOrderService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import DateUtil from "@/utilities/DateUtil";
import StringUtil from "@/utilities/StringUtil";
import FilterUtil from "@/utilities/FilterUtil";
import CustomerPaymentDetailService from "@/services/CustomerPaymentDetailService";

const CustomerPaymentCustomerOrderDetailView = () => import("./CustomerPaymentCustomerOrderDetailView.vue");

class CustomerPaymentCustomerOrderProperties {
    visible: boolean = false;
}

export { CustomerPaymentCustomerOrderProperties };

@Component({
    components: { CustomerPaymentCustomerOrderDetailView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
        },
        filter: {
            orderNo: "",
            orderDateFrom: null,
            orderDateTo: null,
            customerCode: "",
            customerName: "",
            status: "All"
        },
        saving: false,
        selected: [],
        customerOrders: []
    })
})
export default class CustomerPaymentCustomerOrderView extends Vue {
    @Prop({ default: new CustomerPaymentCustomerOrderProperties() }) 
    private properties: CustomerPaymentCustomerOrderProperties;
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderService = new CustomerOrderService();
    private customerPaymentDetailService = new CustomerPaymentDetailService();
    private queueHandle: number = 0;

    private dateTimeFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.order-no"),
                value: "uniqueNo",
                width: "15%",
            },
            {
                text: this.$t("text.order-date"),
                value: "orderDate",
                width: "170px"
            },
            {
                text: this.$t("text.customer-code"),
                value: "customer.code",
            },
            {
                text: this.$t("text.customer-name"),
                value: "customer.name"
            },
            {
                text: this.$t("text.status"),
                value: "status",
                width: "150px"
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                align: "right"
            },
            {
                text: "",
                value: "data-table-expand"
            }
        ];
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.to-pay'),
                value: "To-Pay",
                all: false
            },
            {
                text: this.$t('text.paid'),
                value: "Paid",
                all: false
            },
        ].filter(x => !x.all || withAll);
    }

    public get payment() {
        return this.customerPaymentInfo.payment;
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

    public created() {
        this.load();
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;
        try {
            const filter = this.$data.filter;
            const status = filter.status === 'All' ? ["To-Pay", "Paid"] : [filter.status];

            const r = await this.customerOrderService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                uniqueNo: StringUtil.enclose(filter.orderNo, '%', '%'),
                orderDate: FilterUtil.filterDate(false, filter.orderDateFrom, filter.orderDateTo),
                customerCode: StringUtil.enclose(filter.customerCode, '%', '%'),
                customerName: StringUtil.enclose(filter.customerName, '%', '%'),
                customerId: this.payment.customerId,
                status,
                excludeCustomerPaymentId: this.payment.id,
                loadCustomer: true,
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const customerOrders: any[] = r.data.customerOrders;
			customerOrders.forEach(e => {
				e.totalAmount ??= 0;
			});

            this.$data.customerOrders = customerOrders.sort((lhs, rhs) => rhs.id - lhs.id);;
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const customerOrderIds = this.$data.selected.map(x => x.id);
            await this.customerPaymentDetailService
                .submit(this.payment.id, customerOrderIds);
            this.customerPaymentInfo.events.fire('load');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }
}
