





























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import AutoNumeric, { Options } from "autonumeric";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";
import CustomerPaymentService from "@/services/CustomerPaymentService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from "@/utilities/CommonUtil";
import CustomerPaymentAttachmentService from "@/services/CustomerPaymentAttachmentService";
import SystemSettingService from "@/services/SystemSettingService";

class CustomerPaymentModifyProperties {
    visible: boolean = false;
}

export { CustomerPaymentModifyProperties };

@Component({
    data: () => ({
        attachments: [],
        paymentMethod: "Wallet",
        deliveryMethod: "Standard-Delivery",
        subtotal: 0,
        shippingFee: 0,
        discountAmount: 0,
        totalAmount: 0,
        cashPayment: 0,
        walletPayment: 0,
        receiptPayment: 0,
        voucherNumber: "",
        voucherNumberApplied: "",
        allowedDeliveryMethods: [],
        loading: false,
        saving: false
    })
})
export default class CustomerPaymentModifyView extends Vue {
    @Prop({ default: new CustomerPaymentModifyProperties() }) 
    private properties: CustomerPaymentModifyProperties;
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerPaymentService = new CustomerPaymentService();
    private systemSettingService = new SystemSettingService();
    private inputSubtotal: HTMLInputElement;
    private inputShippingFee: HTMLInputElement;
    private inputDiscountAmount: HTMLInputElement;
    private inputTotalAmount: HTMLInputElement;
    private inputCashPayment: HTMLInputElement;
    private inputWalletPayment: HTMLInputElement;
    private inputReceiptPayment: HTMLInputElement;

    public get deliveryOptions() {
        const deliveryMethod = this.payment.deliveryMethod;
        const allowed: any[] = this.$data.allowedDeliveryMethods;
        return [
            {
                text: this.$t("text.standard-delivery"),
                value: "Standard-Delivery",
            },
            {
                text: this.$t("text.on-hold-delivery"),
                value: "On-Hold-Delivery",
            },
            {
                text: this.$t("text.store-pickup"),
                value: "Store-Pickup",
            },
        ].filter(x => x.value === deliveryMethod || allowed.includes(x.value));
    }

    public get paymentOptions() {
        return [
            {
                text: this.$t('text.cash'),
                value: "Cash",
                visible: true
            },
            {
                text: this.$t("text.wallet"),
                value: "Wallet",
                visible: this.isCheckout
            },
            {
                text: this.$t("text.receipt"),
                value: "Receipt",
                visible: true
            },
            {
                text: this.$t("text.mixed"),
                value: "Mixed",
                visible: true
            }
        ].filter(x => x.visible);
    }

    public get payment() {
        return this.customerPaymentInfo.payment;
    }

    public get isCheckout() {
        return this.payment.type === "Checkout";
    }

    public get isCash() {
        const paymentMethod = this.$data.paymentMethod;
        return paymentMethod === 'Cash' || paymentMethod === 'Mixed';
    }

    public get isWallet() {
        const paymentMethod = this.$data.paymentMethod;
        return paymentMethod === 'Wallet' || paymentMethod === 'Mixed';
    }

    public get isReceipt() {
        const paymentMethod = this.$data.paymentMethod;
        return paymentMethod === 'Receipt' || paymentMethod === 'Mixed';
    }

    public deliveryMethodChanged() {
        this.calculateSummary();
    }

    public paymentMethodChanged() {
        const paymentMethod = this.$data.paymentMethod;
        if (paymentMethod === 'Mixed') {
            AutoNumericUtil.set(this.inputCashPayment, 0);
            AutoNumericUtil.set(this.inputWalletPayment, 0);
            AutoNumericUtil.set(this.inputReceiptPayment, 0);
            this.$data.cashPayment = "0.00";
            this.$data.walletPayment = "0.00";
            this.$data.receiptPayment = "0.00";
        } else if (paymentMethod === 'Receipt') {
            AutoNumericUtil.set(this.inputCashPayment, 0);
            AutoNumericUtil.set(this.inputWalletPayment, 0);
            AutoNumericUtil.set(this.inputReceiptPayment, this.payment.totalAmount);
            this.$data.cashPayment = "0.00";
            this.$data.walletPayment = "0.00";
            this.$data.receiptPayment = this.payment.totalAmount.toFixed(2);
        } else if (paymentMethod === 'Wallet') {
            AutoNumericUtil.set(this.inputCashPayment, 0);
            AutoNumericUtil.set(this.inputWalletPayment, this.payment.totalAmount);
            AutoNumericUtil.set(this.inputReceiptPayment, 0);
            this.$data.cashPayment = "0.00";
            this.$data.walletPayment = this.payment.totalAmount.toFixed(2);
            this.$data.receiptPayment = "0.00";
        } else if (paymentMethod === 'Cash') {
            AutoNumericUtil.set(this.inputCashPayment, this.payment.totalAmount);
            AutoNumericUtil.set(this.inputWalletPayment, 0);
            AutoNumericUtil.set(this.inputReceiptPayment, 0);
            this.$data.cashPayment = this.payment.totalAmount.toFixed(2);
            this.$data.walletPayment = "0.00";
            this.$data.receiptPayment = "0.00";
        }
    }

    public voucherNumberBlur() {
        this.$data.focused = false;
        if (this.$data.voucherNumber !== this.$data.voucherNumberApplied) {
            this.calculateSummary();
        }
    }

    public voucherNumberKeydown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            if (this.$data.voucherNumber !== this.$data.voucherNumberApplied) {
                this.calculateSummary();
            }
        }
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };

            if (this.inputSubtotal) {
                AutoNumericUtil.findOrNew(this.inputSubtotal, null, options)
                    .set(this.$data.subtotal ?? 0);
            }
            if (this.inputShippingFee) {
                AutoNumericUtil.findOrNew(this.inputShippingFee, null, options)
                    .set(this.$data.shippingFee ?? 0);
            }
            if (this.inputDiscountAmount) {
                AutoNumericUtil.findOrNew(this.inputDiscountAmount, null, options)
                    .set(this.$data.discountAmount ?? 0);
            }
            if (this.inputTotalAmount) {
                AutoNumericUtil.findOrNew(this.inputTotalAmount, null, options)
                    .set(this.$data.totalAmount ?? 0);
            }
            if (this.inputCashPayment) {
                AutoNumericUtil.findOrNew(this.inputCashPayment, null, options)
                    .set(this.$data.cashPayment ?? 0);
            }
            if (this.inputWalletPayment) {
                AutoNumericUtil.findOrNew(this.inputWalletPayment, null, options)
                    .set(this.$data.walletPayment ?? 0);
            }
            if (this.inputReceiptPayment) {
                AutoNumericUtil.findOrNew(this.inputReceiptPayment, null, options)
                    .set(this.$data.receiptPayment ?? 0);
            }
        }, 150);
    }

    public async mounted() {
        this.$data.subtotal = this.payment.subtotal;
        this.$data.shippingFee = this.payment.shippingFee;
        this.$data.discountAmount = this.payment.discountAmount;
        this.$data.totalAmount = this.payment.totalAmount;
        this.$data.deliveryMethod = this.payment.deliveryMethod;
        this.$data.paymentMethod = this.payment.paymentMethod;
        this.$data.cashPayment = this.payment.cashPayment;
        this.$data.walletPayment = this.payment.walletPayment;
        this.$data.receiptPayment = this.payment.receiptPayment;
        this.$data.voucherNumber = this.payment.voucherNumber?.code ?? "";
        this.$data.voucherNumberApplied = this.$data.voucherNumber;
        
        await this.loadSystemSetting();
        const dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputSubtotal = dialog.$el.querySelector("#subtotal");
            this.inputShippingFee = dialog.$el.querySelector("#shipping-fee");
            this.inputDiscountAmount = dialog.$el.querySelector("#discount-amount");
            this.inputTotalAmount = dialog.$el.querySelector("#total-amount");
            this.inputCashPayment = dialog.$el.querySelector("#cash-payment");
            this.inputWalletPayment = dialog.$el.querySelector("#wallet-payment");
            this.inputReceiptPayment = dialog.$el.querySelector("#receipt-payment");

            this.updateFormatter();
        }
    }

    public async loadSystemSetting() {
        if (this.$data.loading) {
            return;
        }
        this.$data.loading = true;

        try {
            const r = await this.systemSettingService.allowedDeliveryMethods();
            this.$data.allowedDeliveryMethods = r.data ?? [];
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public async calculateSummary() {
        if (this.$data.loading) {
            return;
        }
        this.$data.loading = true;

        const deliveryMethod = this.$data.deliveryMethod;
        const voucherNumber = this.$data.voucherNumber;

        try {
            const r = await this.customerPaymentService.summary({
                id: this.payment.id,
                deliveryMethod,
                voucherNumber
            });
            this.$data.voucherNumberApplied = voucherNumber;

            this.$data.totalWeight = r.data.totalWeight;
            this.$data.subtotal = r.data.subtotal;
            this.$data.shippingFee = r.data.shippingFee;
            this.$data.discountAmount = r.data.discountAmount;
            this.$data.totalAmount = r.data.totalAmount;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }

            this.$data.voucherNumber = this.$data.voucherNumberApplied;
        } finally {
            this.$data.loading = false;
        }
    }

    public async uploadReceipt() {
        const attachments: File[] = this.$data.attachments;
        var attachmentIds = [];
        if (attachments.length > 0) {
            const service = new CustomerPaymentAttachmentService();

            for (var i = 0; i < attachments.length; i++) {
                var uploader = service.makeUploader(attachments[i]);
                var r = await uploader.upload();
                while (r.continue) {
                    r = await r.continue();
                }
                attachmentIds.push(r.data.id);
            }
        }
        return attachmentIds;
    }

    public async update() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const attachmentIds = await this.uploadReceipt();
            const payment = CommonUtil.clone(this.payment);
            payment.deliveryMethod = this.$data.deliveryMethod;
            payment.paymentMethod = this.$data.paymentMethod;
            payment.cashPayment = this.$data.cashPayment;
            payment.walletPayment = this.$data.walletPayment;
            payment.receiptPayment = this.$data.receiptPayment;

            const r = await this.customerPaymentService.put({
                customerPayment: payment,
                voucherNumber: this.$data.voucherNumber,
                attachmentIds,
                updateVoucherNumber: true
            });

            await this.customerPaymentInfo.events.fire('load');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async submit() {
        const paymentMethod = this.$data.paymentMethod;
        if (paymentMethod === "Receipt") {
            await this.update();
        } else if (paymentMethod === "Wallet" || paymentMethod === "Cash") {
            await this.update();
        } else if (paymentMethod === "Mixed") {
            await this.update();
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }
}
