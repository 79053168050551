var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.create-record')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.finalizeEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('finalize')}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.finalize-payment')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.approveEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('approve')}}},on),[_c('v-icon',[_vm._v("mdi-check-all")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.approve-payment')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.deliveredEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('delivered')}}},on),[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delivered')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.cancelEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('cancel')}}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.cancel-payment')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.rejectEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('reject')}}},on),[_c('v-icon',[_vm._v("mdi-file-remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.reject-payment')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.refundedEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('refunded')}}},on),[_c('v-icon',[_vm._v("mdi-cash-refund")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.refunded')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.printEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('print')}}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.print-receipt')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.deleteEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('delete')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete-record')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }