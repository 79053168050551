





























import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import { CustomerPaymentReceiptProperties } from "./CustomerPaymentReceiptView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentAttachmentService from "@/services/CustomerPaymentAttachmentService";

class CustomerPaymentUploadProperties {
    visible: boolean = false;
}

export { CustomerPaymentUploadProperties };

@Component({
    data: () => ({
        attachments: [],
        saving: false
    })
})
export default class CustomerPaymentUploadView extends Vue {
    @Prop({ default: new CustomerPaymentUploadProperties() }) 
    private properties: CustomerPaymentUploadProperties;
    @Prop() private customerPaymentReceipt: CustomerPaymentReceiptProperties;
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    public get payment() {
        return this.customerPaymentInfo.payment;
    }

    public async uploadReceipt() {
        const attachments: File[] = this.$data.attachments;
        var attachmentIds = [];
        if (attachments.length === 0) {
            var m: any = this.$t("message.please-select-receipt");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            const service = new CustomerPaymentAttachmentService();

            for (var i = 0; i < attachments.length; i++) {
                var uploader = service.makeUploader(attachments[i]);
                var r = await uploader.upload();
                while (r.continue) {
                    r = await r.continue();
                }
                attachmentIds.push(r.data.id);
            }
        }
        return attachmentIds;
    }

    public async update() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const attachmentIds = await this.uploadReceipt();
            if (attachmentIds.length === 0) {
                return;
            }

            const service = new CustomerPaymentAttachmentService();
            await service.submit(this.payment.id, attachmentIds);
            await this.customerPaymentReceipt.events.fire('load');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async submit() {
        await this.update();
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }
}
