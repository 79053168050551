import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerAddressService extends AbstractService {
    private controller = "customer-addresses";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(customerAddress: any, customerId: number, userId: number) {
        const d = { customerAddress, customerId, userId };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(customerAddress: any) {
        const d = { customerAddress };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(customerAddress: any) {
        const d = { customerAddress };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}