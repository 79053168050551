








































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import CustomerAddressService from "@/services/CustomerAddressService";
import CommonUtil from "@/utilities/CommonUtil";
import CustomerPaymentService from "@/services/CustomerPaymentService";

class CustomerPaymentAddressProperties {
    visible: boolean = false;
}

export { CustomerPaymentAddressProperties };

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 0,
            recordCount: 10,
            loading: false,
        },
        addresses: [],
        saving: false
    }),
})
export default class CustomerPaymentAddressView extends Vue {
    @Prop({ default: new CustomerPaymentAddressProperties() })
    private properties: CustomerPaymentAddressProperties;
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerAddressService = new CustomerAddressService();
    private customerPaymentService = new CustomerPaymentService();

    public get headers() {
        return [
            {
                text: this.$t("text.name"),
                value: "name",
                width: "13%"
            },
            {
                text: this.$t("text.address"),
                value: "full"
            },
            {
                text: this.$t("text.contact-person"),
                value: "contactPerson",
                width: "13%"
            },
            {
                text: this.$t("text.contact-no"),
                value: "contactNo",
                width: "13%"
            },
            {
                text: "",
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.load();
    }

    public async load() {
        this.$data.table.loading = true;
        try {
            const payment = this.customerPaymentInfo.payment;
            if (payment.customerId) {
                const r = await this.customerAddressService.get({
                    customerId: payment.customerId,
                    loadCity: true,
                    loadState: true,
                    loadCountry: true,
                });
                for (var i = 0; i < r.data.length; i++) {
                    r.data[i].full = AddressUtil.build(r.data[i]);
                }
                this.$data.addresses = r.data;
            } else {
                this.$data.addresses = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async selected(address: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            if (address) {
                const payment = CommonUtil.clone(this.customerPaymentInfo.payment);
                await this.customerPaymentService.put({
                    customerPayment: payment,
                    shippingId: address.id
                });
                await this.customerPaymentInfo.events.fire('load');
                this.properties.visible = false;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (this.$data.saving) {
            return;
        }

        this.properties.visible = false;
    }
}
