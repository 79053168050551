




























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentEditorControlProperties } from "./CustomerPaymentEditorControlView.vue";
import { CustomerPaymentReceiptProperties } from "./CustomerPaymentReceiptView.vue";
import { CustomerPaymentAddressProperties } from "./CustomerPaymentAddressView.vue";
import { CustomerPaymentModifyProperties } from "./CustomerPaymentModifyView.vue";
import { CustomerPaymentCustomerOrderProperties } from "./CustomerPaymentCustomerOrderView.vue";
import { CustomerPaymentUploadProperties } from "./CustomerPaymentUploadView.vue";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import CustomerPaymentService from "@/services/CustomerPaymentService";
import CompanyService from "@/services/CompanyService";
import RouteUtil from '@/utilities/RouteUtil';
import { CustomerPaymentCustomerProperties } from "./CustomerPaymentCustomerView.vue";
import { CustomerPaymentCreateDialogProperties } from "./CustomerPaymentCreateDialogView.vue";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CustomerPaymentEditorControlView = () => import("./CustomerPaymentEditorControlView.vue");
const CustomerPaymentDetailView = () => import("./CustomerPaymentDetailView.vue");
const CustomerPaymentSummaryView = () => import("./CustomerPaymentSummaryView.vue");
const CustomerPaymentReceiptView = () => import("./CustomerPaymentReceiptView.vue");
const CustomerPaymentAddressView = () => import("./CustomerPaymentAddressView.vue");
const CustomerPaymentModifyView = () => import("./CustomerPaymentModifyView.vue");
const CustomerPaymentUploadView = () => import("./CustomerPaymentUploadView.vue");
const CustomerPaymentCustomerOrderView = () => import("./CustomerPaymentCustomerOrderView.vue");
const CustomerPaymentCustomerView = () => import("./CustomerPaymentCustomerView.vue");
const CustomerPaymentCreateDialogView = () => import("./CustomerPaymentCreateDialogView.vue");

class CustomerPaymentInfoProperties {
    currency: any = {};
    payment: any = { customer: {} };
    details: any[] = [];
    dateTimeFormat: string = null;
    saving: boolean = false;
    events = new EventManager();
}

export { CustomerPaymentInfoProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/customer-payment.css" }]
	},
    components: {
        AlertDialogView,
        CustomerPaymentEditorControlView,
        CustomerPaymentDetailView,
        CustomerPaymentSummaryView,
        CustomerPaymentReceiptView,
        CustomerPaymentAddressView,
        CustomerPaymentModifyView,
        CustomerPaymentUploadView,
        CustomerPaymentCustomerOrderView,
        CustomerPaymentCreateDialogView,
        CustomerPaymentCustomerView
    },
    data: () => ({
        loading: false
    }),
})
export default class CustomerPaymentInfoView extends Vue {
    private properties = new CustomerPaymentInfoProperties();
    private alertDialog = new AlertDialogProperties;
    private editorControl = new CustomerPaymentEditorControlProperties();
    private customerPaymentReceipt = new CustomerPaymentReceiptProperties();
    private customerPaymentUpload = new CustomerPaymentUploadProperties();
    private customerPaymentAddress = new CustomerPaymentAddressProperties();
    private customerPaymentModify = new CustomerPaymentModifyProperties();
    private customerPaymentCustomerOrder = new CustomerPaymentCustomerOrderProperties();
    private customerPaymentCustomer = new CustomerPaymentCustomerProperties();
    private customerPaymentCreateDialog = new CustomerPaymentCreateDialogProperties();
    private customerPaymentService = new CustomerPaymentService();
    private companyService = new CompanyService();

    public get payment() {
        return this.properties.payment;
    }

    public get isCheckout() {
        return this.payment.type === 'Checkout';
    }

    public refreshEditorControl() {
        const payment = this.properties.payment;

        if (payment.status === 'Draft') {
            this.editorControl.finalizeEnabled = true;
            this.editorControl.approveEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.refundedEnabled = false;
            this.editorControl.printEnabled = false;
            this.editorControl.deleteEnabled = true;
        } else if (payment.status === 'Processing') {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.approveEnabled = true;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.cancelEnabled = true;
            this.editorControl.rejectEnabled = true;
            this.editorControl.refundedEnabled = false;
            this.editorControl.printEnabled = false;
            this.editorControl.deleteEnabled = false;
        } else if (payment.status === 'Approved') {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.approveEnabled = false;
            this.editorControl.deliveredEnabled = true;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.refundedEnabled = false;
            this.editorControl.printEnabled = true;
            this.editorControl.deleteEnabled = false;
        } else if (payment.status === 'Delivered' || //
            payment.status === 'Completed') {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.approveEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.refundedEnabled = false;
            this.editorControl.printEnabled = true;
            this.editorControl.deleteEnabled = false;
        } else if (payment.status === 'Cancelled' || //
            payment.status === 'Rejected') {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.approveEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.refundedEnabled = true;
            this.editorControl.printEnabled = false;
            this.editorControl.deleteEnabled = false;
        } else if (payment.status === 'Refunded') {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.approveEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.refundedEnabled = false;
            this.editorControl.printEnabled = false;
            this.editorControl.deleteEnabled = false;
        }
    }

    public created() {
        this.customerPaymentCreateDialog.events.subscribe("created", this.createdRecord);
        this.properties.events.subscribe('load', this.load);
        this.load();
    }

    public destroyed() {
        this.customerPaymentCreateDialog.events.remove('created', this.createdRecord);
        this.properties.events.remove('load', this.load);
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadPayment() {
        var id = this.$route.query.id;
        const r = await this.customerPaymentService.get({
            id,
            loadCustomer: true,
			loadFacebookAccount: true,
            loadShipping: true,
            loadVoucherNumber: true
        });
        if (r.data.customerPayments.length === 0) {
            throw new Error(this.$t("message.record-not-found").toString());
        }

        const payment = r.data.customerPayments[0];

        payment.subtotal = payment.subtotal ?? 0;
        payment.shippingFee = payment.shippingFee ?? 0;
        payment.discountAmount = payment.discountAmount ?? 0;
        payment.totalAmount = payment.totalAmount ?? 0;
        payment.cashPayment = payment.cashPayment ?? 0;
        payment.walletPayment = payment.walletPayment ?? 0;
        payment.receiptPayment = payment.receiptPayment ?? 0;

        if (payment.shipping) {
            payment.shipping.address = AddressUtil.build(payment.shipping);
        }

        this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
        this.properties.payment = payment;
        await this.properties.events.fire("load-details");
        await this.properties.events.fire("loaded");

        this.refreshEditorControl();
    }

    public async load() {
        this.$data.loading = true;

        try {
            await this.loadCurrency();
            await this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public async finalize() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.finalize(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public create() {
        this.customerPaymentCreateDialog.visible = true;
    }

    public async createdRecord(customerPayment: any) {
        await this.$router.push("/management/customer/customer-payments/modify?id=" + customerPayment.id);
        this.$route.query.id = customerPayment.id;
        this.load();
    }

    public async approve() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.approve(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async delivered() {
         if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.delivered(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async cancel() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.cancel(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async reject() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.reject(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async refunded() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            await this.customerPaymentService.refunded(customerPayment);
            this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async receipt() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            const customerPayment = this.properties.payment;
            const r = await this.customerPaymentService.receipt(customerPayment);
            const customerReceipt = r.data.customerReceipt;

            const url = "/api/reports/customer-receipt";
            const q = {
                "lang": this.$i18n.locale,
                "receiptNo": customerReceipt.uniqueNo,
                "reportType": customerPayment.type
            };
            window.open(RouteUtil.makeUrl(url, q), "_blank");
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } 
        } finally {
            this.properties.saving = false;
        }
    }

    public async remove() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            await this.customerPaymentService.delete(this.payment);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public async control(button: string) {
        if (button === "create") {
            this.create();
        } else if (button === "finalize") {
            this.finalizeDialog();
        } else if (button === "approve") {
            this.approveDialog();
        } else if (button === "delivered") {
            this.deliveredDialog();
        } else if (button === "cancel") {
            this.cancelDialog();
        } else if (button === "reject") {
            this.rejectDialog();
        } else if (button === "refunded") {
            this.refundedDialog();
        } else if (button === "print") {
            await this.receipt();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public finalizeDialog() {
        const t = this.$t("title.finalize-payment");
        const m = this.$t("message.finalize-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "finalize" };
        this.alertDialog.visible = true;
    }

    public approveDialog() {
        const t = this.$t("title.approve-payment");
        const m = this.$t("message.approve-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "approve" };
        this.alertDialog.visible = true;
    }

    public deliveredDialog() {
        const t = this.$t("title.delivered");
        const m = this.$t("message.delivered-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "delivered" };
        this.alertDialog.visible = true;
    }

    public cancelDialog() {
        const t = this.$t("title.cancel-payment");
        const m = this.$t("message.cancel-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "cancel" };
        this.alertDialog.visible = true;
    }

    public rejectDialog() {
        const t = this.$t("title.reject-payment");
        const m = this.$t("message.reject-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "reject" };
        this.alertDialog.visible = true;
    }

    public refundedDialog() {
        const t = this.$t("title.refunded");
        const m = this.$t("message.refunded-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "refunded" };
        this.alertDialog.visible = true;
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "finalize" && button === this.$t("text.yes")) {
                await this.finalize();
            } else if (tag.command === "approve" && button === this.$t("text.yes")) {
                await this.approve();
            } else if (tag.command === "delivered" && button === this.$t("text.yes")) {
                await this.delivered();
            } else if (tag.command === "cancel" && button === this.$t("text.yes")) {
                await this.cancel();
            } else if (tag.command === "reject" && button === this.$t("text.yes")) {
                await this.reject();
            } else if (tag.command === "refunded" && button === this.$t("text.yes")) {
                await this.refunded();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }
        
        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/customer/customer-payments");
        }

        await this.properties.events.fire("alert-dialog-closed");
    }
}
